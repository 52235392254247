import { ENVIRONMENT_LOCAL_STORAGE_KEY, SANDBOX as ENVIRONMENT_SANDBOX } from '@mqd/mqd-constants';
import currentUserStore from 'stores/CurrentUserStore';

const validPrivateSandboxRoutes = [
  'applicationReset',
  'home',
  'credit',
  'creditTemplateEditor',
  'creditWelcome',
  'creditBundle',
  'creditBundles',
  'creditBundlesCreate',
  'creditPolicyCreate',
  'creditPolicyLibrary',
  'creditPolicyLibraryCreditProductsDashboard',
  'creditPolicyLibraryCreditProduct',
  'creditPolicyLibraryAPRsDashboard',
  'creditPolicyLibraryAPR',
  'creditPolicyLibraryFeesDashboard',
  'creditPolicyLibraryFee',
  'creditPolicyLibraryRewardsDashboard',
  'creditPolicyLibraryReward',
  'creditPolicyLibraryOffersDashboard',
  'creditBundleWorkQueue',
  'creditProductApprovalQueue',
  'creditProducts',
  'creditProductsRejected',
  'creditProduct',
  'creditProductsActivity',
  'creditProductsHistory',
  'creditProductCreate',
  'creditOffers',
  'creditOffersHistory',
  'creditOffer',
  'creditOfferCreate',
  'creditAccounts',
  'creditAccount',
  'creditAccountCard',
  'creditDigitalWalletToken',
  'creditAccountPaymentInitiation',
  'creditAccountPaymentDetails',
  'creditPaymentSourceDetails',
  'creditAccountPaymentAccount',
  'creditAccountDelinquencyCollectionDetails',
  'creditAccountStatementDetails',
  'creditAccountTransactionDetails',
  'creditTemplates',
  'program',
  'programAccountholders',
  'programAccountholder',
  'programAccountholderActivity',
  'programAccountholderAccount',
  'programAccountholderAccountActivity',
  'programAccountholderCards',
  'programAccountholderCard',
  'programAccountholderCardActivity',
  'programAccountholderActivate',
  'programAccountholderSuspend',
  'programAccountholderTerminate',
  'programAccountholderTransaction',
  'programCardCreation',
  'programCardholders',
  'programCardholder',
  'programCard',
  'programCardProduct',
  'programCardProducts',
  'programCommandoMode',
  'programCommandoModes',
  'programReviews',
  'programReviewDetails',
  'risk',
  'riskRealTimeDecisioningDashboard',
  'riskTransactionReport',
];

export const isInvalidSandboxRoute = (routeKey) => {
  const {
    userStore: { privateSandboxActive },
  } = currentUserStore;
  const activeEnvironment = localStorage.getItem(ENVIRONMENT_LOCAL_STORAGE_KEY);

  return (
    privateSandboxActive &&
    activeEnvironment === ENVIRONMENT_SANDBOX &&
    !validPrivateSandboxRoutes.includes(routeKey)
  );
};
